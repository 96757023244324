import {React, useEffect, useRef} from 'react';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

const Languages = () => {
  const container = useRef(); 

  useEffect(() => {
    createWidget("EeNoJkO3", { container: container.current, height:600 });
  }, []);

  return (
    <>
      <div className="form-page">
        <div ref={container} />
      </div>
    </>
  ) 
};

export default Languages;
