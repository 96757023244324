import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Languages from './components/pages/Languages';
import Feedback from './components/pages/Feedback';
import Submit from './components/pages/Submit';
/* import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/pro-solid-svg-icons';
import { faLanguage } from '@fortawesome/pro-light-svg-icons'; */

/* library.add(faLanguage); */

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/languages" exact component={Languages} />
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/submit" exact component={Submit} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
