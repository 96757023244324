import React from 'react';
import '../css/Pages.css';

function Home() {
    return (
        <div className="text-page">
            <h1>Help us become better</h1>
            <p>We strive to make Soothing Relaxation as good as possible, but we are always happy to receive feedback on how we can improve.</p>
            <p>On this site, you can submit corrections to our translations on <a href="/languages"> YouTube</a> or send us more <a href="/feedback">general feedback</a> if you have something on your mind. We would love to hear from you!</p>
        </div>
    )
}

export default Home
