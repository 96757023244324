import './css/Navbar.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/SR.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';

function Navbar() {
    const [menuClick, setMenuClick] = useState(false);


    const handleMenuClick = () => {
        setMenuClick(!menuClick);
    }
    const closeMobileMenu = () => setMenuClick(false);
    const handleResize = () => {
        if (window.innerWidth > 800){
            setMenuClick(false);
        };
    };

    window.addEventListener('resize', handleResize);
    return (
        <>
            <header className="header">
                <nav className="navbar">
                    <Link to="/" className="logo-link" onClick={closeMobileMenu}>
                        <img src={logo} alt="Soothing Relaxation logo" className="logo-image"></img>
                    </Link>
                     
                    <ul className={menuClick ? 'nav-menu nav-menu-active': 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="/submit" className="nav-link" onClick={closeMobileMenu}>
                                Submit media
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/languages" className="nav-link" onClick={closeMobileMenu}>
                                Language mistakes 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/feedback" className="nav-link" onClick={closeMobileMenu}>
                                Feedback 
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link" onClick={closeMobileMenu}>
                                About
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="https://soothingrelaxation.com/" target="_blank" className="nav-link external-link" onClick={closeMobileMenu}>
                                soothingrelaxation.com
                            </a>
                        </li>
                    </ul>

                    <div className="mobile-menu-icon" onClick={handleMenuClick}>
                        {menuClick ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                    </div> 
                                 
                </nav>

            </header>
        </>
    )
}

export default Navbar;
