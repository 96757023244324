import React from 'react';
import Card from '../Card';
import '../css/Pages.css';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons';

/* 
@fortawesome/pro-light-svg-icons
@fortawesome/pro-solid-svg-icons
@fortawesome/pro-regular-svg-icons
*/

function Home() {
    return (
        
        <div className="full-width">
            <motion.h1 class="home-headline"
            initial={{opacity: 0}}
            animate={{opacity:1}}
            transition={{duration:1}}>What do you want to do?</motion.h1>
            <div className="home-grid-container">
                <motion.div className="home-grid-item" whileHover={{ scale: 1.1 }}
whileTap={{ scale: 0.9 }} transition={{ duration: 0.5 }}>
                    <div class="grid-content">
                        <Link to={"/languages"} className="card-link">
                            <Card title="Language mistakes" icon={faLanguage} text="Report a language mistake." gradient="card-blue-gradient" />
                        </Link>
                    </div>
                </motion.div>
                <motion.div className="home-grid-item" whileHover={{ scale: 1.1 }}
whileTap={{ scale: 0.9 }} transition={{ duration: 0.5 }}>
                    <div class="grid-content">
                    <Link to={"/feedback"} className="card-link">
                        <Card title="General feedback" icon={faCommentsAlt} text="Submit feedback to us." gradient="card-blue-gradient" />
                    </Link>
                    </div>
                </motion.div>
                <motion.div className="home-grid-item" whileHover={{ scale: 1.1 }}
whileTap={{ scale: 0.9 }} transition={{ duration: 0.5 }}>
                    <div class="grid-content">
                        <Link to={"/submit"} className="card-link">
                            <Card title="Submit media" icon={faPhotoVideo} text="Submit your music or video." gradient="card-blue-gradient" />
                        </Link>
                    </div>
                </motion.div>
            </div>
        </div>

    )
}

export default Home
