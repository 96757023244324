import React from 'react';
import './css/Card.css';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Card({title, text, icon, gradient}) {
    return (
        <>
            <div className={"card " + gradient}>
                <motion.div className="card-title">{title}</motion.div>
                <div className="card-icon"><FontAwesomeIcon icon={icon} /></div>
                <div className="card-text">{text}</div>
            </div>
        </>
    )
}

export default Card;
